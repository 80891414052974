import React, { Component } from 'react'
import { graphql } from 'gatsby'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Image from 'gatsby-image'
import { HeroContainer, HeroHeading } from '../components/HeroHeading'

const StyledPost = styled.div`
  align-content: center;
  text-align: left;
  margin: 50px 15%;

  line-height: 30px;

  @media only screen and (max-width: 45em) {
    margin: 50px 20px;
  }
  & h1 {
    text-align: center;
  }
  & .centeredText {
    text-align: center;
  }
  & .gatsby-highlight pre[class*='language-'].line-numbers {
    padding-left: 2.8em;
  }
  /**
   * Add back the container background-color, border-radius, padding, margin
   * and overflow that we removed from <pre>.
   */
  .gatsby-highlight {
    border-radius: 0.3em;
    margin: 0.5em 0;
    padding: 1em;
    overflow: auto;
  }

  /**
   * Remove the default PrismJS theme background-color, border-radius, margin,
   * padding and overflow.
   * 1. Make the element just wide enough to fit its content.
   * 2. Always fill the visible space in .gatsby-highlight.
   * 3. Adjust the position of the line numbers
   */
  .gatsby-highlight pre[class*='language-'] {
    background-color: transparent;
    margin: 0;
    padding: 0;
    overflow: initial;
    min-width: 100%;
  }

  /* Inline code */
  p :not(pre) > code[class*='language-'] {
    position: relative;
    padding: 0.2em;
    border-radius: 0.3em;
    color: #c92c2c;
    border: 1px solid rgba(0, 0, 0, 0.1);
    display: inline;
    white-space: normal;
  }
`

StyledPost.propTypes = {
  className: PropTypes.string,
}

const StyledHeroContainer = styled(HeroContainer)`
  height: 40vh;
`
const StyledHeroImage = styled(Image)`
  height: 100%;
`
const EmptyImage = styled.div`
  height: 100%;
`
const ImageTint = styled.div`
  position: absolute;
  z-index: 64;
  height: 100%;
  width: 100%;
  background-color: rgba(0.5, 0.5, 0.5, 0.6);
  transform: translate(0%, -100%);
`

class BlogPost extends Component {
  render() {
    const {
      title,
      markdown,
      publishDate,
      featureImage,
    } = this.props.data.contentfulBlogPost
    return (
      <>
        <StyledHeroContainer>
          {featureImage ? (
            <StyledHeroImage
              sizes={{
                ...featureImage.fluid,
                base64: featureImage.sqip.dataURI,
              }}
            />
          ) : (
            <EmptyImage />
          )}

          <ImageTint />
          <HeroHeading>
            <h1>{title}</h1>
            <p className={'centeredText'}>{publishDate}</p>
          </HeroHeading>
        </StyledHeroContainer>
        <StyledPost
          dangerouslySetInnerHTML={{
            __html: markdown.childMarkdownRemark.html,
          }}
        />
      </>
    )
  }
}
BlogPost.propTypes = {
  data: PropTypes.object.isRequired,
}
export default BlogPost

export const query = graphql`
  query($slug: String!) {
    contentfulBlogPost(slug: { eq: $slug }) {
      title
      publishDate(formatString: "MMMM Do, YYYY")
      slug
      featureImage {
        ...BigSqipPreview
      }
      markdown {
        childMarkdownRemark {
          html
        }
      }
    }
  }
`
